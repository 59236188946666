var generic = generic || {};
var site = site || {};

(function ($, Drupal) {
  'use strict';

  var videoToutV1 = {
    createVideoPlayerDiv: function ($elem, i) {
      var id = $elem.attr('class') + '-' + i;
      $elem.parent().append('<div />').children('div').attr('id', id);
      $elem.remove();
      return id;
    },
    addVideo: function (ytId, iframeId) {
      /*global YT */
      var player = new YT.Player(iframeId, {
        height: '100%',
        width: '100%',
        videoId: ytId,
        playerVars: {
          controls: 1,
          showinfo: 0,
          rel: 0
        },
        events: {
          onReady: function (event) {
            // Taken from - http://stackoverflow.com/a/13819253
            var isMobile = {
              Android: function () {
                return navigator.userAgent.match(/Android/i);
              },
              BlackBerry: function () {
                return navigator.userAgent.match(/BlackBerry/i);
              },
              iOS: function () {
                return navigator.userAgent.match(/iPhone|iPad|iPod/i);
              },
              Opera: function () {
                return navigator.userAgent.match(/Opera Mini/i);
              },
              Windows: function () {
                return (
                  navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i)
                );
              },
              any: function () {
                return (
                  isMobile.Android() ||
                  isMobile.BlackBerry() ||
                  isMobile.iOS() ||
                  isMobile.Opera() ||
                  isMobile.Windows()
                );
              }
            };
            // Don't autoplay on any mobile device
            if (!isMobile.any()) {
              event.target.playVideo();
            }
          }
        }
      });
    },
    setup: function (event, $context) {
      var self = this;
      var $videoTout = $('.js-video-tout', $context);
      $videoTout.each(function (i) {
        var $content = $('.js-video-tout__wrapper', $(this)),
          $vids = $content.filter(function (i) {
            return (
              $(this).data('video-provider') === 'youtube' && $(this).data('youtube-id').length > 0
            );
          });
        $vids.each(function () {
          var $wrap = $(this),
            ytId = $wrap.attr('data-youtube-id'),
            thisYtId = $wrap.attr('data-youtube-id'),
            $playTrigger = $('.js-video-tout__trigger', $wrap);
          $playTrigger.keydown(function (e) {
            if (site.getKeycode(e) === 13) {
              $playTrigger.trigger('click');
            }
          });
          $playTrigger.once().on('click', function (e) {
            e.preventDefault();
            var scrollPosition = $(window).scrollTop();
            var classList = (function () {
              if ($wrap.hasClass('js-video-tout__close-button-white')) {
                return 'overlay__video-tout overlay__video-tout--close-white';
              }
              return 'overlay__video-tout';
            })();
            generic.overlay.launch({
              content: '<div class="js-video-tout-placeholder"></div>',
              transition: 'none',
              fixed: 'true',
              appearDuration: 1.0,
              opacity: 0,
              maxWidth: '100%',
              maxHeight: '100%',
              overflow: 'hidden',
              cssStyle: {
                border: 'none',
                padding: 0,
                backgroundColor: '#000',
                height: '100%',
                width: '100%',
                position: 'fixed',
                className: classList,
                onComplete: function () {
                  var _this = this;
                  _this.$cbox = $('#colorbox.overlay__video-tout');
                  var iframeId = self.createVideoPlayerDiv(
                    $('.js-video-tout-placeholder', _this.$cbox),
                    Math.floor(+new Date()) / 1000
                  );
                  self.addVideo(ytId, iframeId);
                  _this.$cbox.addClass('active');
                  setTimeout(function () {
                    $('body').css({
                      overflow: 'hidden',
                      height: $(window).height()
                    });
                  }, 1000);
                },
                onCleanup: function () {
                  var _this = this;
                  _this.$cbox.removeClass('active');
                  $('body').css({
                    overflow: '',
                    height: ''
                  });
                  $(window).scrollTop(scrollPosition);
                }
              }
            });
            $(window)
              .off('resize.videoToutResize')
              .on('resize.videoToutResize', function () {
                $.colorbox.resize({
                  width: '100%',
                  height: '100%'
                });
              });
            return false;
          });
        });
      });
    }
  };
  $(document).on('youtubeIframeAPI.loaded', function (e) {
    var $context = $(this);
    videoToutV1.setup(e, $context);
  });
})(jQuery, Drupal);
